$threads-font-path: '~plaid-threads/fonts';
@import '~plaid-threads/scss/typography';
@import '~plaid-threads/scss/variables';

$border: 2px solid $black200;
$breakpoint-vertical: 1000px;

/*
Base
*/

// Add global box-sizing
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $white;
}

.App {
  align-items: center; // Center horizontally
  width: 100%; // Ensure full width
  max-width: 220 * $unit; // Increased from 300 * $unit to accommodate all components
  margin: auto; // Center the container itself
  //padding: 1 * $unit 1 * $unit 1 * $unit 1 * $unit; // Balanced padding
}

.container {
  display: flex;
  flex-direction: row; /* Ensures the children (left and right sections) are laid out in a row */

  @media (max-width: $breakpoint-vertical) {
    flex-direction: column-reverse;
    width: 98vw; // 100% width of the viewport width
  }
}

.left-section {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: left;
  margin-right: 1 * $unit;
  width: 100%; /* 30% width of the container */
  max-width: 60 * $unit;
  min-width: 60 * $unit;

  @media (max-width: $breakpoint-vertical) {
    width: 100%;
    margin-right: 1 * $unit;
    max-width: $breakpoint-vertical;
    min-width: unset;
  }
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: right;
  width: 100%; /* 70% width of the container */
  min-width: 150 * $unit;

  @media (max-width: $breakpoint-vertical) {
    width: 100%;
    margin: 0 auto;
    min-width: unset;
  }
}

/*

shared styles

*/
.bottom-border-content {
  //overflow: hidden;
  border-bottom: 1px solid $black1000;
  margin-bottom: 1 * $unit;
  padding-bottom: 1 * $unit;
  //min-width: 210  * $unit;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-vertical) {
    //min-width: 65 * $unit;
    
  }
}

.box {
  border: $border;
  border-radius: $border-radius;
  margin-bottom: 3 * $unit;
  box-shadow: $shadow-xsmall;
}

.card {
  display: flex;
  padding: 2rem;
  align-items: center;
}

.heading {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.banner-header {
  display: flex;
  justify-content: space-between;
  margin-top: 1 * $unit;
  margin-bottom: 0.5 * $unit;

  @media (max-width: $breakpoint-vertical) {
    flex-direction: column;
    width: 98vw;
  }
}

.nav-item {
  display: inline-block;
  margin-right: 1 * $unit;
}

.value {
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.toast__background {
  background: $white;
}

.toast__link {
  color: $white;
  text-decoration: underline;
}

.Toastify__toast--error.toast__background {
  background: $red800;
}

.toast__body {
  color: $black1000;
  font-size: 2 * $unit;
  white-space: pre-line;
  padding-left: $unit;
}

.Toastify__toast--error > .toast__body {
  color: $white;
}

/*

Content at the top of the view

*/

.everpresent-header-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align children to the start of the cross axis */
}

.everpresent-content__heading {
  margin-bottom: 0 * $unit;
}

.everpresent-content__subheading {
  font-size: 1 * $unit;
  font-weight: 300;
  line-height: 1 * $unit;
}

nav {
  align-self: flex-end;
  margin-top: 0.1 * $unit;
  border: none;
  margin: 0;

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    border: 0;
    margin: 0;
    //gap: 20px;

    li {
      display: inline;

      a {
        text-decoration: none;
        color: #007bff;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }
}

/*

Landing overview

*/

.landing__column {
  width: 33%;
  float: left;
}

.landing__value {
  overflow-x: hidden;
  padding-right: 1rem;
}

.btnsContainer {
  display: flex;
  margin: 2 * $unit 0;
}

.btnWithMargin {
  margin-left: 2 * $unit;
}

/*

Modal

*/

input::placeholder {
  line-height: 1.5;
}

/*

UserList

*/

.user-list-heading {
  font-weight: normal;
}

/*

UserCard

*/

.user-card__box {
  box-shadow: $shadow-medium;
  border-bottom: $border;
}
.user-card {
  display: flex;
}

.user-card-clickable {
  width: 100%;
}

.user-card__detail {
  display: flex;
  justify-content: space-between;
  //margin-left: 3 * $unit;
}

.hoverable {
  width: 100%;
  flex: 3;
  display: flex;
}

.user-card__buttons {
  padding-left: 3 * $unit;
  display: grid;
  grid-row-gap: $unit;
  justify-content: center;
  flex: 1;
}

/*

Item overview

*/

.item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1 * $unit 0;
}
.item__header-heading {
  margin-bottom: 1rem;
  font-weight: normal;
}

.item-card {
  padding: 0 $unit 0 0;
  background-color: $black100;
  border-bottom: $border;
  box-shadow: $shadow-medium;
  display: grid;
  grid-template-columns: 9fr 1fr;

  &.expanded {
    box-shadow: none;
  }
}

.item-card__clickable {
  padding: (2 * $unit) 0 (2 * $unit) (2 * $unit);
  width: 95%;
  display: grid;
  grid-template-columns: 45% 30% 25%;
}

.item-card__column-1 {
  display: flex;
  align-items: center;
  padding-right: 2 * $unit;
}

.item-card__img {
  height: 4 * $unit;
  padding-right: 1rem;
}

/*

Account row

*/
.account-data-row {
  border-bottom: $border;
  padding: 3 * $unit;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.account-data-row:after {
  clear: both;
  content: '';
  display: table;
}

.account-data-row__name {
  font-size: 2 * $unit;
  line-height: 4 * $unit;
  display: inline-block;
  font-weight: 500;
}

/*

Add User Form

*/

.addUserForm {
  margin-top: 3 * $unit;
}

.add-user__column-1 {
  width: 30%;
  padding-right: 3 * $unit;
}

.add-user__heading {
  font-size: 2 * $unit;
}

.add-user__value {
  font-size: 1.4rem;
}

.add-user__column-2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .input_field {
    margin-bottom: 0;
  }
}

.add-user__column-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30%;
}

.add-user__button {
  margin: 0 2 * $unit;
  min-width: 10 * $unit;
}

/*

transactions-table

*/

/*

More Details

*/
.more-details {
  position: relative;
}

.menuOption {
  line-height: 1.8rem !important;
  padding-top: $unit;
}

/*

net worth

*/

.tableSubHeading {
  margin-top: $unit;
}

.netWorthText {
  font-family: $font-stack-monospace;
  font-size: 1 * $unit;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.netWorthContainerBox {
  .netWorthContainerHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1 * $unit;
  }
  .netWorthDollars {
    margin-top: $unit;
    margin-bottom: $unit;
    margin-right: 2 * $unit;
  }

  .netWorthHeading {
    margin-top: 1 * $unit;
    margin-bottom: 0;
  }
}

.holdingsContainer {
  display: flex;

  @media (max-width: $breakpoint-vertical) {
    flex-direction: column;
    width: 100%;
  }
}

.userDataBoxAssets {
  width: 50%;
  min-height: 20 * $unit;
  border: $border;
  //margin-right: 2 * $unit;

  @media (max-width: $breakpoint-vertical) {
    flex-direction: column;
    width: 100%;
  }
}

.userDataBox {
  width: 69 * $unit;
  min-height: 30 * $unit;
  border: $border;
  margin-right: 2 * $unit;

  @media (max-width: $breakpoint-vertical) {
    margin-right: 0;
    width: 100%;
  }
}

.holdingsList {
  padding: 4 * $unit;

  .title {
    font-weight: bold;
  }
}

.holdingsListVendor {
  padding: 2 * $unit;

  .title {
    font-weight: bold;
    margin-top: 1 * $unit;
    margin-bottom: 1 * $unit;
  }
}

.holdingsListCategories {
  //padding: 2 * $unit;

  @media (max-width: $breakpoint-vertical) {
    //padding: 1 * $unit;
    
  }
}

.holdingsListMonthlyCost {
  width: 100%;
  padding: 1 * $unit;

  .title {
    font-weight: bold;
  }

  @media (max-width: $breakpoint-vertical) {
    padding: 1 * $unit;
  }

  .costHeading {
    //font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  .totalTrendsTop {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.assetsHeaderContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dollarsHeading {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: $unit;
}

.holdingsHeading {
  margin-top: 0;
  margin-bottom: 0;
}

.data,
.personalAsset {
  display: grid;
  grid-template-columns: 5fr 5fr;
  width: 100%;

  .title {
    font-weight: bold;
  }

  .dataItem {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-vertical) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

/*

Monthly Spending

*/

.monthlySpendingContainer {
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;

  @media (max-width: $breakpoint-vertical) {
    margin-right: 0;
    flex-direction: column;
    width: 100%;
  }
}

.monthlySpendingHeading {
  margin-top: 2 * $unit;
  margin-bottom: 0;
}

.monthlySpendingText {
  margin-bottom: 4 * $unit;
  font-family: $font-stack-monospace;
  font-size: 2 * $unit;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.recharts-wrapper {
  //margin: 0 0 * $unit;
}

.spendingInsightData {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  overflow-y: auto;
  max-height: 70 * $unit;
  grid-row-gap: 0.1 * $unit; // Adjust the value as per your requirement

  @media (max-width: $breakpoint-vertical) {
    margin-right: 0;
    width: 100%;
    max-height: 70 * $unit;
  }
}

.userDataBoxPieChart,
.userDataBoxVendor {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 10px;
  min-width: 300px;
  border: $border;

  @media (max-width: $breakpoint-vertical) {
    margin-right: 0;
    width: 100%;
    
  }
}

.userDataBoxBarChart {
  width: 100%;
  min-height: 30 * $unit;
  border: $border;
  //margin-right: 2 * $unit;

  @media (max-width: $breakpoint-vertical) {
    margin-right: 0;
    width: 100%;
  }
}

.categoryChartButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clearSelectionButton {
  background-color: #4a90e2;
  border: none;
  color: white;
  font-size: 1.1em;
  cursor: pointer;
  padding: 1 * $unit 1 * $unit 1 * $unit 1 * $unit;
  border-radius: 1 * $unit;
}

.switchChartTypeButton {
  font-size: 2 * $unit;
  background-color: #fefffe;
  border: none;
  cursor: pointer;
  padding: 1 * $unit 1 * $unit 0 * $unit 1 * $unit;
  border-radius: 0.5 * $unit;
}

/*

Loading

*/

.loading {
  display: grid;
  grid-template-rows: 150px 100px;
  grid-row-gap: 50px;
  justify-items: space-around;
  width: 50%;
  margin: auto;
  margin-top: -125px;
}

/*

ErrorMessage

*/

.errMsgCallout {
  position: relative;
  margin-bottom: 3 * $unit;

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* Styling for the input box */
.nice-input {
  width: 100%; /* Full width */
  padding: 10px 15px; /* Spacing inside the input */
  margin: 8px 0; /* Spacing outside the input */
  display: inline-block; /* Allows for inline placement */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Makes sure padding doesn't affect overall width */
  font-size: 16px; /* Larger, more readable text */
  color: #333; /* Darker text color for readability */
  background-color: white; /* White background */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus effect */
}

.settings-new-input {
  width: auto;
  @extend .nice-input;
}

/* Hover state */
.nice-input:hover {
  border-color: #888; /* Darker border on hover */
}

/* Focus state */
.nice-input:focus {
  outline: none; /* Removes the default focus outline */
  border-color: #4a90e2; /* Blue border on focus */
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.5); /* Subtle glow effect */
}

.pagination-list {
  margin: 0 auto; /* Centers the pagination list */
  padding: 10px 0; /* Adds padding on top and bottom for spacing */
  list-style: none; /* Removes default list styling */
  display: flex;
  justify-content: center; /* Keeps pagination centered */
}

.pagination-item {
  margin: 0 5px; /* Adds spacing between pagination items */
}

.pagination-link {
  padding: 8px 12px; /* Increases padding for a larger clickable area */
  background-color: #f0f0f0; /* Light grey background */
  border: 1px solid #ccc; /* Light grey border */
  color: #333; /* Dark grey text */
  cursor: pointer;
  text-decoration: none; /* Removes underline from links */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.pagination-link:hover, .pagination-link:focus {
  background-color: #e0e0e0; /* Darker grey background on hover/focus */
}

.pagination-link.active {
  background-color: #333;
  color: #fff;
}

.pagination-link.active, .pagination-item.active .pagination-link {
  background-color: #007bff; /* Blue background for active state */
  color: #fff; /* White text for active state */
  border-color: #007bff; /* Blue border for active state */
}

.table-filter-sort-container {
  display: flex;
  align-items: center;
}

.nice-text {
  font-size: 1.1em;
  color: #333;
  margin-right: 10px;
  font-weight: bold;
}

.filter-input {
  //flex: 0 0 40%;
  margin-right: 1 * $unit;
}

.sort-input {
  flex: 0 0 20%;
  margin-right: 1 * $unit;
}

.page-input {
  flex: 0 0 10%;
  text-justify: center;
}

.nice-button {
  background-color: #4a90e2;
  border: none;
  color: white;
  padding: (1 * $unit) 0 (1 * $unit) (1 * $unit);
  text-align: center;
  display: inline-block;
  font-size: 2 * $unit;
  margin: 0.5 * $unit 0.5 * $unit;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
}

.centered {
  text-align: center;
}

.add-account-button {
  width: 99%;
  font-size: 2 * $unit;
  // get all from nice-button without having to repeat
  @extend .nice-button;

  // Add more styles here when hovering
  &:hover {
    background-color: #0056b3;
  }
}

.rule-link-button {
  width: 99%;
  text-align: left;
  height: 7 * $unit;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // get all from nice-button without having to repeat
  @extend .nice-button;
}

.plus-button {
  display: inline-block;
  padding: 10px 20px; /* Adjust the padding as needed */
  font-size: 16px; /* Adjust font-size as needed */
  text-align: center;
  vertical-align: middle;
  background-color: #fff; /* Example background color */
  cursor: pointer;
  border: none;
  outline: none;
}

.custom-table {
  width: 99%;
  border-collapse: collapse;

  th, td {
    padding: 1 * $unit;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }

  .transactions-data-rows:nth-child(even) {
    background-color: #f9f9f9;
  }

  button {
    padding: 4px 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #45a049;
  }

  /* Responsive table */
  @media only screen and (max-width: $breakpoint-vertical) {
    th:nth-child(1),
    td:nth-child(1) {
      display: table-cell; // Show the first th
      text-align: left;
      width: 1%;
    }

    td:nth-child(2),
    th:nth-child(2),
    td:nth-child(3),
    th:nth-child(3),
    td:nth-child(4),
    th:nth-child(4),
    td:nth-child(5),
    th:nth-child(5),
    td:nth-child(6),
    th:nth-child(6),
    td:nth-child(7),
    th:nth-child(7),
    td:nth-child(8),
    th:nth-child(8) {
      display: none; // Hide the second and third td
    }

    td:nth-child(9),
    th:nth-child(9),
    td:nth-child(11),
    th:nth-child(11) {
      display: table-cell;
      width: 30%;
      word-wrap: break-word;
      white-space: normal;
      word-break: break-word;
    }

    td:nth-child(10),
    th:nth-child(10) {
      width: 5%;
    }

    td:nth-child(12),
    th:nth-child(12),
    td:nth-child(13),
    th:nth-child(13) {
      width: 5%;
      text-align: right;
      //word-break: break-word;
    }
  }
}

.rule-table-button {
  width: 8 * $unit;
  height: 3 * $unit;
  margin: 0.1 * $unit;
}

.input_login {
  background-color: transparent;
  border-radius: 0.2rem;
  border: 1px solid #949494;
  font-family: Cern, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 4.8rem;
  height: 4.8rem;
  padding: 0 1.6rem;
  width: 100%;
}

.transaction-header {
  display: flex;
  justify-content: space-between;
  margin-top: 1 * $unit;
  margin-bottom: 0.5 * $unit;

  @media (max-width: 350px) {
    flex-direction: column;
  }
}

.transactions-modal .Modal-module_modalPortalContent__3F_jp {
  max-width: 95% !important;
  //max-height: 95% !important;
  align-items: center;
  //overflow: hidden;
}

.transactions-modal .transactions {
  width: 90%;
  height: 100%;
}

.transactions-modal .table-filter-sort-container {
  //visibility: hidden;
  //width: 95%;
}

.transactions-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 1 * $unit rgba(0, 0, 0, 0.1);

  th,
  td {
    padding: 2 * $unit;
    background-color: #ffffff;
    border-bottom: 0.1 * $unit solid #c3c3c3;
    width: 10%;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 40%;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 20%;
  }

  th {
    text-align: left;
  }

  td {
    text-align: left;
  }

  tr:hover {
    background-color: #f3f3f3;
  }

  .table-category-select {
    display: inline-block;
    min-width: 100px; /* Adjust minimum width as needed */
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 50%;

    &:hover {
      background-color: #f0f0f0;
    }

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  /* Responsive table */
  @media (max-width: $breakpoint-vertical) {
    //table-layout: unset;
    th:nth-child(1) {
      display: table-cell; // Show the first th
      text-align: left;
    }

    td:nth-child(1) {
      display: table-cell; // Show the first td
      text-align: left;
    }

    td:nth-child(2),
    th:nth-child(2),
    td:nth-child(3),
    th:nth-child(3) {
      display: none; // Hide the second and third td
    }

    td:nth-child(1),
    th:nth-child(1) {
      width: 40%;
    }
    td:nth-child(4),
    th:nth-child(4) {
      width: 20%;
    }
    td:nth-child(5),
    th:nth-child(5) {
      width: 20%;
    }
  }
}

.mobile-only {
  display: none;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 22, 40, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2 * $unit;
  border-radius: 1 * $unit;
  width: 80%;
  margin: 1 * $unit;

  div {
    margin-bottom: 1 * $unit;
  }

  label {
    display: block;
    margin-bottom: 1 * $unit;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 1 * $unit;
    border-radius: 1 * $unit;
    border: 0.2 * $unit solid #ccc;
    font-size: 2 * $unit;
  }
}

.modal-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;

  button {
    padding: 1 * $unit;
    border: none;
    border-radius: 1 * $unit;
    font-size: 2 * $unit;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &[type='submit'] {
      background-color: #cbeccc; /* Green */
      color: rgb(0, 0, 0);

      &:hover {
        background-color: #8fef92;
      }
    }

    &[type='button'] {
      background-color: #eed7d5; /* Red */
      color: rgb(0, 0, 0);

      &:hover {
        background-color: #ebb5b2;
      }
    }
  }
}

.custom-tooltip {
  background-color: #f9f9f9;
  border: 0.2 * $unit solid #ddd;
  border-radius: 1 * $unit;
  padding: 1 * $unit;
  font-size: 2 * $unit;

  .label {
    margin-bottom: 1 * $unit;
    font-weight: bold;
  }

  .intro {
    color: #888;
  }

  .desc {
    color: #333;
  }
}

.rule-form {
  display: flex;
  flex-direction: column;
  gap: 1 * $unit;
  padding: 2 * $unit;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .serial-row {
    display: flex;
    justify-content: center;
    gap: 1 * $unit;

    label {
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    input {
        padding: 1 * $unit;
        border: 1px solid #ccc;
        border-radius: 4px;
        &:focus {
          outline: none;
          border-color: #0056b3;
          box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25);
        }
      }
  }

  .form-row {
    display: grid;
    grid-template-columns: 3fr 0.5fr 3fr;
    gap: 10px;
    align-items: center;

    .input-group {
      display: grid;
      gap: 5px;

      label {
        font-weight: bold;
        display: block;
      }

      input {
        padding: 1 * $unit;
        border: 1px solid #ccc;
        border-radius: 4px;
        &:focus {
          outline: none;
          border-color: #0056b3;
          box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25);
        }
      }
    }

    .conversion-sign {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .button-row {
    display: flex;
    justify-content: center;
    gap: 15px;

    button {
      padding: 10px 20px;
      border: none;
      color: #fff;
      background-color: #007bff;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }

      &:first-child {
        background-color: #dc3545;
        &:hover {
          background-color: #c82333;
        }
      }
    }

  }
}

.category-dropdown {
  position: static;
  overflow: hidden;
  width: 100%;
  //display: flex;

  .dropdown {
    position: static;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    max-height: 10 * $unit;
  }
}


